import { Route } from 'react-router-dom';
import { Library } from './Library';
import { Executions } from './Executions';
import { Message } from './Message';

export const messageManagerRoutes = [
    <Route exact path="/executions" component={Executions} />,
    <Route exact path="/library/:id" component={Library} />,
    <Route exact path="/library/:id/message/:mid" component={Message} />
]
