// import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { Title } from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import MaterialLink from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import NextLink from '../common/NextLink';
import { Button } from '@material-ui/core';
import Moment from 'react-moment';
import {Link, useParams} from 'react-router-dom';
import { fetchMmgrApi } from "./api";
import ReactAudioPlayer from 'react-audio-player';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export const Message = () => {
    const classes = useStyles();
    let { id, mid } = useParams();

    const [data, setData] = useState();

    useEffect(() => {
        if(id){
            fetchMmgrApi('get', '/library/'+id+'/message/'+mid).then(v => {
                setData(v);
            });
        }
    }, [id, mid]);

    if(!data){
        return <div>Loading...</div>;
    }

    return <Container>
    <Box my={2}>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/executions">
                Message Libraries
            </Link>
            <Link color="inherit" to={"/library/"+id}>
                Library
            </Link>
            <Typography color="textPrimary">Message: {data.message.message_id}</Typography>
        </Breadcrumbs>
    </Box>

    <Box my={2}>
        <Typography component="h1" variant="h4" color="inherit" noWrap>
            Message preview
        </Typography>
    </Box>

    <Box my={2}>
        <Card>
            <CardContent>
                <Typography color="textSecondary" gutterBottom>Display message text</Typography>
                <Typography variant="body2" component="h2">
                    {data.message.text_message}
                </Typography>
            </CardContent>
        </Card>
    </Box>

    <Box my={2}>
        <Card>
            <CardContent>
                <Typography color="textSecondary" gutterBottom>Play audio</Typography>
                <ReactAudioPlayer
                    src={data.audioUrl}
                    controls
                />
            </CardContent>
        </Card>
    </Box>

    <Box my={2}>
        <Card>
            <CardContent>
                <Typography color="textSecondary" gutterBottom>Phrases ({data.phrases.length})</Typography>
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="simple table">
                        <TableBody>
                            {data.phrases.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell component="th" scope="row">
                                        {row.phrase_text}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    </Box>


    <Box my={2}>
        <Typography component="h2" variant="h5" color="inherit" noWrap>
            Events ({data.events.length})
        </Typography>
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Event</TableCell>
                        <TableCell>Trip ID</TableCell>
                        <TableCell>Stop ID</TableCell>
                        <TableCell>Stop Seq</TableCell>
                        <TableCell>Play Seq</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.events.slice(0, 100).map((row, i) => (
                        <TableRow key={i}>
                            <TableCell component="th" scope="row">
                                {row.event}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.trip_id}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.stop_id}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.stop_sequence}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.play_sequence}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Box>
</Container>
};
