import { DataProvider, fetchUtils, GetListParams } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = 'https://anytrip.com.au/api/v3';
const httpClient = fetchUtils.fetchJson;

const anytripStopProvider = {
    getList: (resource, params) => {
        const query = {
            q: params.filter.fullName
        }
        const url = `${apiUrl}/search?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            // console.log({json})
            return ({
            data: json.response.stops.map((stop) => {stop.stop.id = stop.stop.id.replace('au2:',''); return stop.stop}),
            total: json.response.stops.length
        })});
    },

    getOne: async (resource, params) => {
        return {data: await httpClient(`${apiUrl}/stop/au2:${params.id}`).then(({ json }) => {
            json.response.stop.id = json.response.stop.id.replace('au2:','');
            return json.response.stop
        })};
    },

    getMany: async (resource, params) => {
        return { data: await Promise.all(params.ids.map(stopId => {
            return httpClient(`${apiUrl}/stop/au2:${stopId}`).then(({ json }) => {
                json.response.stop.id = json.response.stop.id.replace('au2:','');
                return json.response.stop
            });
        }))}
    },

    getManyReference: (resource, params) => {
        return Promise.resolve({data: [], total: 0})
    },

    update: (resource, params) => Promise.resolve({data: ({})}),

    updateMany: (resource, params) => Promise.resolve({data: ([])}),

    create: (resource, params) => Promise.resolve({data: ({})}),

    delete: (resource, params) => Promise.resolve({data: ({})}),

    deleteMany: (resource, params) => Promise.resolve({data: ([])}),
};

export default anytripStopProvider