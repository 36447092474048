/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessageSubstitution = /* GraphQL */ `
  query GetMessageSubstitution($id: ID!) {
    getMessageSubstitution(id: $id) {
      id
      source
      destination
      createdAt
      updatedAt
    }
  }
`;
export const listMessageSubstitutions = /* GraphQL */ `
  query ListMessageSubstitutions(
    $filter: ModelMessageSubstitutionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageSubstitutions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source
        destination
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      type
      event
      template
      routes
      routesCustom
      trips
      tripsCustom
      stops
      playSequence
      validFrom
      validTo
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        event
        template
        routes
        routesCustom
        trips
        tripsCustom
        stops
        playSequence
        validFrom
        validTo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
