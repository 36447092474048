import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';

export const fetchMmgrApi = async (method, path, params) => {
    return API[method]('AdminQueries', path, {
        ...(params||{}),
        headers: {
            "Content-Type": "application/json",
            ...((params && params.headers)||{}),
            Authorization: `${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
        },
    })
}