import React, { useEffect, useMemo, useState } from "react";
import {
    AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  FormTab,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TabbedForm,
  TextField,
  TextInput
} from "react-admin";
import { useFormState } from 'react-final-form';
import { AmplifyFilter } from "react-admin-amplify";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MaterialUiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Twig from 'twig';
import { getModeIcons } from "../anytrip.utils";
import { fetchMmgrApi } from "./MessageManager/api";

const messageTypes = ['VEHICLE', 'LOCATION'];
const messageEvents = {
    VEHICLE: [
        "VEHICLE_AT_ORIGIN",
        "VEHICLE_DEPART_ORIGIN",
        "VEHICLE_IN_TRANSIT",
        "VEHICLE_APPROACH",
        "VEHICLE_AT",
        "VEHICLE_DEPARTURE",
        "VEHICLE_APPROACH_DESTINATION",
        "VEHICLE_AT_DESTINATION",
    ],
    LOCATION: [
        "LOCATION_APPROACH",
        "LOCATION_AT",
        "LOCATION_PASS",
    ],
};

const routeTypes = [
    {id: '*', name: 'All routes (*)'},
    {id: '4T.T.*', name: 'All trains (4T.T.*)'},
    {id: '4T.C.*', name: 'All coaches (4T.C.*)'},
    {id: '4T.T.SCO', name: 'Intercity Trains - SCO (4T.T.SCO)'},
    {id: '4T.T.SHL', name: 'Intercity Trains - SHL (4T.T.SHL)'},
    {id: '4T.T.HUN', name: 'Intercity Trains - HUN (4T.T.HUN)'},
    {id: '4T.T.BMT', name: 'Intercity Trains - BMT (4T.T.BMT)'},
    {id: '4T.T.W*', name: 'Regional Trains - Western NSW (4T.T.W*)'},
    {id: '4T.T.SP*,4T.T.ST*', name: 'Regional Trains - Southern NSW (4T.T.ST/SP*)'},
    {id: '4T.T.NT*', name: 'Regional Trains - North Coast (4T.T.NT*)'},
    {id: '4T.T.NP*', name: 'Regional Trains - North West (4T.T.NP*)'},
    {id: '__custom__', name: 'Custom...'},
]

const tripTypes = [
    {id: '*', name: 'All trips'},
    {"id":"NP23.*","name":"NP23 (223)"},{"id":"NP24.*","name":"NP24 (224)"},{"id":"NP43.*","name":"NP43 (243)"},{"id":"NP44.*","name":"NP44 (244)"},{"id":"NT31.*","name":"NT31 (31)"},{"id":"NT32.*","name":"NT32 (32)"},{"id":"NT33.*","name":"NT33 (33)"},{"id":"NT34.*","name":"NT34 (34)"},{"id":"NT35.*","name":"NT35 (35)"},{"id":"NT36.*","name":"NT36 (36)"},{"id":"SP31.*","name":"SP31 (631)"},{"id":"SP32.*","name":"SP32 (632)"},{"id":"SP33.*","name":"SP33 (633)"},{"id":"SP34.*","name":"SP34 (634)"},{"id":"SP35.*","name":"SP35 (635)"},{"id":"SP36.*","name":"SP36 (636)"},{"id":"SP41.*","name":"SP41 (641)"},{"id":"SP42.*","name":"SP42 (642)"},{"id":"ST21.*","name":"ST21 (621)"},{"id":"ST22.*","name":"ST22 (622)"},{"id":"ST23.*","name":"ST23 (623)"},{"id":"ST24.*","name":"ST24 (624)"},{"id":"WP45.*","name":"WP45 (445)"},{"id":"WP46.*","name":"WP46 (446)"},{"id":"WT27.*","name":"WT27 (427)"},{"id":"WT28.*","name":"WT28 (428)"},
    {id: '__custom__', name: 'Custom...'},
]

Twig.extendFilter("allStationsTo", (value) => {
    if(value.length === 1){
        return `only`;
    }

    if(value.length > 1){
        return `First stop, ${value.map((s, i) => {
            if(i === value.length-1){
                return `and then ${s}`;
            }else if(i === 1){
                return `then ${s}`;
            }
            return s
        }).join(', ')}.`;
    }
});

const titleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
}
const toChoices = items => items.map(item => ({ id: item, name: titleCase(item.replace(/_/g, ' ')) }));

const EventInput = props => {
    const { values } = useFormState();
    return (
        <SelectInput
            choices={values.type ? toChoices(messageEvents[values.type]) : []}
            {...props}
        />
    );
};

const OptionalSelectInput = props => {
    const { values } = useFormState();
    return (<>
        <SelectInput
            {...props}
        />
        {values[props.source] === '__custom__' ? <TextInput source={props.source + 'Custom'} /> : null}
    </>);
};

const supportedSubstitutions = [
    {s: 'stoppingPattern | allStationsTo', ex: 'First stop, Penrith. Then, Emu Plains and Mount Victoria'},
    {s: 'departureTime', ex: '12:34'},
    {s: 'destination', ex: 'Mount Victoria via Strathfield'},
    {s: 'stationName', ex: 'Central'},
    {s: 'platformType', ex: 'Platform'},
    {s: 'platformNumber', ex: '2'}
]

const PreviewTemplate = props => {
    const { values } = useFormState();
    const [context, setContext] = useState(null);

    useEffect(() => {
        setContext(null);
        fetchMmgrApi('get', '/editorPreview/sampleTrip', {queryStringParameters: {
            stop_id_pattern: values.stops ? values.stops.join(',') : '',
            trip_id_pattern: values.trips === '__custom__' ? values.tripsCustom : values.trips,
            route_id_pattern: values.routes === '__custom__' ? values.routesCustom : values.routes,
            event: values.event
        }}).then(v => {
            setContext(v);
        }).catch(v => {
            setContext(false);
        });
    }, [values.stops, values.trips, values.routes, values.tripsCustom, values.routesCustom, values.event]);

    const preview = useMemo(() => {
        try{
            var template = Twig.twig({
                data: values.template || ''
            });

            return template.render(context != null ? context : {
                stoppingPattern: ['Strathfield', 'Parramatta', 'Blacktown', 'Penrith', 'Emu Plains', 'Mount Victoria'],
                departureTime: '12:34',
                destination: 'Mount Victoria via Strathfield',
                platformType: 'Platform',
                platformNumber: '1',
                stationName: 'Central'
            })
        }catch(e){
            return e.message || 'Unknown error';
        }

    }, [context, values.template])
    return (
        <Grid container spacing={3}>
            <Grid item xs={8}>
                {context === false && <Typography variant="body2" component="p">
                    There was a problem finding a trip that fit the criteria.  The preview below is based on a random trip.
                </Typography>}
                <Card>
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                        Template Preview
                        </Typography>
                        <Typography variant="body2" component="p">
                        {context === null ? 'Loading...' : preview}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card>
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                        Supported substitutions
                        </Typography>
                        <MaterialUiList>
                            {supportedSubstitutions.map(({s, ex}, i) => <ListItem key={i}><ListItemText primary={`{{${s}}}`} secondary={`e.g. ${ex}`} /></ListItem>)}
                        </MaterialUiList>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        
    );
};

const defaultQuery = "listMessages";

export const MessageList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="type" label="Type" />
        <TextField source="event" label="Event" />
        <TextField source="template" label="Template" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const MessageShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField
        source="customerID"
        reference="customers"
        label="Customer"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="accountRepresentativeID"
        reference="accountRepresentatives"
        label="Account representative"
        link="show"
      >
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField
        source="productID"
        reference="products"
        label="Product"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="status" />
      <NumberField source="amount" />
      <DateField source="date" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

const validateRequired = [required()];

const commonForm = <TabbedForm>
    <FormTab label="Target">
        <TextInput source="id" disabled />
        <SelectInput source="type" validate={validateRequired} choices={toChoices(messageTypes)} initialValue={'VEHICLE'} />
        <EventInput source="event" validate={validateRequired} />
        <OptionalSelectInput source="routes" choices={routeTypes} initialValue="*" />
        <OptionalSelectInput source="trips" choices={tripTypes} initialValue="*" />
        <ReferenceArrayInput
            source="stops"
            reference="stops"
            label="Stops"
            filterToQuery={(searchText) => {
                return { fullName: searchText }
            }}
        >
            <AutocompleteArrayInput optionText={(record) => {
                return record ? <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    {getModeIcons(record.modes).map((imgUrl, i) => {
                        return <img key={i} src={imgUrl} style={{width: 18, height: 18}} alt="Mode icon" />;
                    })}
                    <span>&nbsp;{record.fullName}, {record.locality || ''}</span>
                </div> : 'Loading...'
            }} helperText="Select stops by using autocomplete" matchSuggestion={(_) => {
                return true
            }} />
        </ReferenceArrayInput>
        <NumberInput
            source="playSequence"
            label="Play Sequence"
            initialValue={0}
            min={0}
            step={1}
        />
    </FormTab>
    <FormTab label="Template">
        <TextInput
            fullWidth
            source="template"
            label="Message Template"
            multiline
        />
        <PreviewTemplate />
    </FormTab>
    <FormTab label="Validlity">
        <DateInput format={v => !v ? undefined : v} source="validFrom" />
        <DateInput format={v => !v ? undefined : v} source="validTo" />
    </FormTab>
</TabbedForm>;

export const MessageEdit = (props) => (
  <Edit {...props}>
    {commonForm}
  </Edit>
);

export const MessageCreate = (props) => (
  <Create {...props}>
    {commonForm}
  </Create>
);
