import { API, graphqlOperation } from "@aws-amplify/api";
import { Amplify } from "@aws-amplify/core";
import React from "react";
import { Admin, MenuItemLink, Resource, Responsive } from "react-admin";
import {
  buildAuthProvider,
  CognitoGroupList,
  CognitoUserList,
  CognitoUserShow,
} from "react-admin-amplify";
import awsExports from "./aws-exports";
import { combinedDataProvider } from "./combined.provider";
import { Dashboard } from "./components/Dashboard";
import { MessageCreate, MessageEdit, MessageList } from "./components/Message";
import { messageManagerRoutes } from "./components/MessageManager";
import { MessageSubstitutionsCreate, MessageSubstitutionsEdit, MessageSubstitutionsList } from "./components/MessageSubstitutions";
import * as queries from "./graphql/queries";
import LabelIcon from '@material-ui/icons/Label';

Amplify.configure(awsExports);

const authProvider = buildAuthProvider({
  authGroups: ["superadmin", "admin"],
});

const menuItems = [
  { name: '', text: 'Dashboard', icon: <LabelIcon /> },
  { name: 'messages', text: 'Message Templates', icon: <LabelIcon /> },
  { name: 'messageSubstitutions', text: 'Substitutions', icon: <LabelIcon /> },
  { name: 'executions', text: 'Message libraries', icon: <LabelIcon /> },
];

const MyMenu = ({ onMenuClick, logout }) => (
<div>
  { menuItems.map(item => (
    <MenuItemLink
      key={item.name}
      to={`/${item.name}`}
      primaryText={item.text}
      leftIcon={item.icon}
      onClick={onMenuClick}
    />
  ))}
  <Responsive
    small={logout}
    medium={null} 
  />
</div>
);


function App() {
  return (
    <Admin
      menu={MyMenu}
      authProvider={authProvider}
      dataProvider={combinedDataProvider}
      // loginPage={LoginPage}
      dashboard={Dashboard}
      customRoutes={messageManagerRoutes}
    >
      {(permissions) => [
        <Resource
          options={{ label: 'Message Templates' }}
          name="messages"
          list={MessageList}
          edit={MessageEdit}
          // edit={OrderEdit}
          create={MessageCreate}
        />,
        <Resource
          name="messageSubstitutions"
          options={{ label: 'Substitutions' }}
          list={MessageSubstitutionsList}
          edit={MessageSubstitutionsEdit}
          // edit={OrderEdit}
          create={MessageSubstitutionsCreate}
        />,
        <Resource name="stops" />,
        permissions.includes("superadmin") ? (
          <Resource
            name="cognitoUsers"
            options={{ label: "Users" }}
            list={CognitoUserList}
            show={CognitoUserShow}
          />
        ) : null,
        permissions.includes("superadmin") ? (
          <Resource
            name="cognitoGroups"
            options={{ label: "Groups" }}
            list={CognitoGroupList}
          />
        ) : null,
      ]}
    </Admin>
  );
}

export default App;
