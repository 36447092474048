import {
    buildDataProvider,
  } from "react-admin-amplify";
import anytripStopProvider from "./anytrip.provider";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";

const dataProvider = buildDataProvider(
    {
      queries,
      mutations,
    },
    {
      enableAdminQueries: true,
    }
);

export const combinedDataProvider  = {
    ...dataProvider,
    getList: (resource, params) => {
        if(resource === 'stops'){
            return anytripStopProvider.getList(resource, params)
        }

        return dataProvider.getList(resource, params);
    },
    getOne: (resource, params) => {
        if(resource === 'stops'){
            return anytripStopProvider.getOne(resource, params)
        }

        return dataProvider.getOne(resource, params);
    },
    getMany: (resource, params) => {
        if(resource === 'stops'){
            return anytripStopProvider.getMany(resource, params)
        }

        return dataProvider.getMany(resource, params);
    }
}
