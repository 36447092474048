// import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { Title } from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import NextLink from '../common/NextLink';
import { Button } from '@material-ui/core';
import Moment from 'react-moment';
import {Link} from 'react-router-dom';
import { fetchMmgrApi } from "./api";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export const Executions = () => {
    const classes = useStyles();

    const [data, setData] = useState();
    const updateData = () => {
        fetchMmgrApi('get', '/executions').then(v => {
            setData(v);
        });
    }

    useEffect(() => {
        let interval = setInterval(() => {
            updateData();
        }, 30 * 1000);
        updateData();
        return () => {
            clearInterval(interval)
        }
    }, []);

    if(!data){
        return <div>Loading...</div>;
    }

    return <Container>
        <Box my={2}>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">Message Libraries</Typography>
            </Breadcrumbs>
        </Box>
        <Box my={2}>
            <Typography component="h1" variant="h6" color="inherit" noWrap>
                Message Libraries
            </Typography>
            <Button variant="contained" color="primary" onClick={async () => {
                await fetchMmgrApi('post', '/execution');
                updateData();
            }}>Trigger library re-generation</Button>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Message Library ID</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Started</TableCell>
                            <TableCell>Ended</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.executions.slice(0, 4).map((row) => (
                            <TableRow key={row.executionArn}>
                                <TableCell component="th">
                                    {row.status === 'SUCCEEDED' ? <Link to={"/library/" + encodeURIComponent(row.name)}>{row.name}</Link> : <span>{row.name}</span>}
                                </TableCell>

                                <TableCell scope="row">
                                    {row.status}
                                </TableCell>

                                <TableCell scope="row">
                                    <Moment format="D MMM YYYY HH:mm">{row.startDate}</Moment><br />(<Moment fromNow>{row.startDate}</Moment>)
                                </TableCell>

                                <TableCell scope="row">
                                    <Moment format="D MMM YYYY HH:mm">{row.stopDate}</Moment><br />(<Moment fromNow>{row.stopDate}</Moment>)
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </Container>
};
