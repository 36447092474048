/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:bc7bfbcc-6237-4684-896e-2a0d4f869dac",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_HPM6z5fI6",
    "aws_user_pools_web_client_id": "4tv5n91stjc3qi5rfatagv35v",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://n5on7o6u4bas7lssf7u2kfdn4u.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://7i72y8b770.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        },
        {
            "name": "mmgrapi",
            "endpoint": "https://baw5sng7lc.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "reactadminamplifydemf4c30126856a4ef9908a9a5a18c62756-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
