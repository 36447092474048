import React, { useMemo, useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput
} from "react-admin";

const defaultQuery = "listMessageSubstitutions";

export const MessageSubstitutionsList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="source" label="Original" />
        <TextField source="destination" label="Replacement" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const commonForm = <SimpleForm>
    <TextInput
        fullWidth
        source="source"
        label="Original"
    />
    <TextInput
        fullWidth
        source="destination"
        label="Replacement"
    />
</SimpleForm>;

export const MessageSubstitutionsEdit = (props) => (
  <Edit {...props}>
    {commonForm}
  </Edit>
);

export const MessageSubstitutionsCreate = (props) => (
  <Create {...props}>
    {commonForm}
  </Create>
);
