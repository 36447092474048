/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessageSubstitution = /* GraphQL */ `
  mutation CreateMessageSubstitution(
    $input: CreateMessageSubstitutionInput!
    $condition: ModelMessageSubstitutionConditionInput
  ) {
    createMessageSubstitution(input: $input, condition: $condition) {
      id
      source
      destination
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageSubstitution = /* GraphQL */ `
  mutation UpdateMessageSubstitution(
    $input: UpdateMessageSubstitutionInput!
    $condition: ModelMessageSubstitutionConditionInput
  ) {
    updateMessageSubstitution(input: $input, condition: $condition) {
      id
      source
      destination
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageSubstitution = /* GraphQL */ `
  mutation DeleteMessageSubstitution(
    $input: DeleteMessageSubstitutionInput!
    $condition: ModelMessageSubstitutionConditionInput
  ) {
    deleteMessageSubstitution(input: $input, condition: $condition) {
      id
      source
      destination
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      type
      event
      template
      routes
      routesCustom
      trips
      tripsCustom
      stops
      playSequence
      validFrom
      validTo
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      type
      event
      template
      routes
      routesCustom
      trips
      tripsCustom
      stops
      playSequence
      validFrom
      validTo
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      type
      event
      template
      routes
      routesCustom
      trips
      tripsCustom
      stops
      playSequence
      validFrom
      validTo
      createdAt
      updatedAt
    }
  }
`;
